@media (min-width:0px) and (max-width:520px) {
    .smartedu_head{
        height: 80px !important;
    }

    .smartedu_top{
        height: 100% !important;
        width: 100% !important;
    }

    .smartedu_top img{
        width:60px !important;
        height: auto !important;
    }

    .smartedu_logo2{
        font-size: 14px !important;
        letter-spacing: 6.5px !important;
        font-weight: bold !important;
    }

    .smartedu_logo2 span {
        letter-spacing: -1px;
        font-size: 14px !important;
    }

    .smartedu_login{
        display: none !important;
    }

    .smartedu_nav{
        display: none !important;
    }

    .smartedu_bottom-box {
        height:250px !important;
    }


    .smartedu_bottom-contain{
        width: 100% !important;
        height:250px !important;
    }
    .smartedu_bottom-img-box{
        display: block !important;
    }
    .smartedu_bottom-mobil{
        justify-content: flex-start !important;
    }
    .smartedu_bottom-icon{
        margin-left: 0 !important;
    }

}
@media (min-width:0){
    .smartedu_head{
        background: url(../../assets/img/header_bg.jpeg) top center #0243af;
        background-size: 100% 112px;
        overflow: hidden;
        height: 112px;
    }
    .smartedu_top{
        width: 1200px;
        height: 100px;
        display: flex;
        margin: 0px auto;
        padding-top: 12px;
        justify-content: space-between;
        color: #fff;
        position: relative;
    }
    .smartedu_logo_top{
        display: flex;
        align-items: center;
    }
    .smartedu_logo2{
        vertical-align: super;
        letter-spacing: 11px;
        display: inline-block;
        margin-left: 18px;
        border-left: 1px solid #fff;
        padding-left: 18px;
        font-size: 20px;
        color: #fff;
        margin-bottom: 0px;
    }

    .smartedu_login{
        position: absolute;
        right: 0px;
        top: 12px;
        background: #5388FF;
        border: 1px solid #2864E8;
        line-height: 32px;
        height: 32px;
        border-radius: 20px;
        font-size: 16px;
        cursor: pointer;
        color: #6E9BFF;
        padding: 0px 10px;
    }
    .smartedu_left_a{
        position: absolute;
        left: 0px;
        width: 88px;
        height: 56px;
    }
    .smartedu_right_a{
        position: absolute;
        left: 115px;
        width: 245px;
        height: 56px;
    }
    .smartedu_login a{
        color: #fff;
        padding: 0px 10px;
    }
    .smartedu_nav{
        height: 22px;
        margin-top: 54px;
    }
    .smartedu_nav span{
        padding: 0px 0px 0px 15px;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
    }

    .smartedu_nav ul{
        display: flex;
    }
    .smartedu_nav li{
        padding: 0px 10px;
        color: #fff;
        font-size: 18px;
    }
    .smartedu_bottom-box {
        width: 100%;
        height: 188px;
        opacity: 1;
        background: rgba(0, 11, 43, 1);
        margin-top: 45px;
    }

    .smartedu_bottom-contain {
        width: 1200px;
        height: 188px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 14px;
    }

    .smartedu_bottom-icon{
        margin-left: 50px;
    }
    .smartedu_bottom-p{
        margin-bottom: 30px;
        color: #fff;
    }
    .smartedu_bottom-p-2{
        margin-bottom: 10px;
    }
    .smartedu_bottom-img-box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .smartedu_bottom-mobil{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;

    }
    .smartedu_text-center{
        text-align: center;
    }
    .smartedu_icon-mobil{
        height: 15px;
        width: 15px;
        margin-right: 10px;
    }
}
