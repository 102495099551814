.trainingDetails {
  width: 100%;
  min-height: 100vh;
  z-index: 1000;
  background: #f6f7fb;
  display: block;
  float: left;
  :global{
    .trainingDetails_span{
      width: 14rem;
      margin: auto;
      margin-top: .2rem;
      span{
        font-size: .16rem;
        color: #333333;
        i{
          color: #888888;
          list-style: none;
          font-style: initial;
        }

      }
    }
    .top_title{
      background: #FFFFFF;
      box-shadow: 0px .04rem .15rem rgba(45, 64, 101, 0.08);
      height:.60rem;
      width: 100%;
      line-height: .60rem;
      text-align: center;
      font-family:'Microsoft YaHei';
      font-style: normal;
      font-weight: 500;
      font-size: .18rem;
      color: #2A283D;
      opacity: 0.99;
      position: relative;
      .return_wapper{
        position: absolute;
        left: .20rem;
        font-family:'Microsoft YaHei';
        font-style: normal;
        font-weight: normal;
        font-size: .18rem;
        color: #333333;
        line-height: .60rem;
        cursor: pointer;
        span{
          margin-left: .27rem;
        }
        svg{
          position: absolute;
          top:.18rem;
        }
      }
    }
    .router{
      width: 14rem;
      margin: auto;
      height: .70rem;
      line-height: .70rem;
    }
    .main_box{
      background: #FFFFFF;
      border-radius: .05rem;
      margin: auto;
      width:14rem;
      min-height: calc(100vh - 2.4rem);
      box-sizing: border-box;
      margin-top: .22rem;
      margin-bottom:.42rem;
      .news_title{
        font-family:'Microsoft YaHei';
        font-style: normal;
        color: #333333;
        padding:.60rem 1.9rem .35rem 1.9rem;
        box-sizing: border-box;
        width: 14rem;
        height: 1.8rem;
        .tra_title{
          font-weight: 500;
          font-size: .32rem;
          line-height: .66rem;
        }
        .autherInfo{
          padding-left: .77rem;
          position: relative;
          display: inline-block;
          svg{
            position: absolute;
            left: .60rem;
            top: .03rem;
          }
        }
      }
      .bottom_box{
        padding: .30rem 1.90rem;
        .title {
          color: #333333;
          font-size: .16rem;
          font-weight: 500;
          margin-bottom: .20rem;
          &::before {
            content: "";
            width: .02rem;
            height: .13rem;
            display: inline-block;
            margin-right: .15rem;
            background: #E74436;
            border-radius: .02rem;
          }
        }
        .jd_box{
          .img_box{
            display: flex;
            flex-wrap: wrap;
            margin-bottom:0;
            >li{
              margin-right: .30rem;
              width: 2.29rem;
              height: 1.68rem;
              border-radius: .05rem;
              overflow: hidden;
              img{
                width: 100%;
                height: 100%;
              }
            }
          }
          .school_box{
            margin-left: -2%;
            overflow: hidden;
            li{
              float: left;
              width: 23%;
              margin-left: 2%;
              line-height: .30rem;
              &::before {
                content: "";
                width: .02rem;
                height: .02rem;
                display: inline-block;
                margin-right: .13rem;
                background: #000;
                border-radius: 100%;
                position: relative;
                top: -.03rem;
              }
            }
          }
          .text{
            word-break: break-word;
            word-wrap: break-word;
            white-space: -o-pre-wrap;
            white-space: -moz-pre-wrap;
            white-space: pre;
            white-space: pre-wrap;
            white-space: pre-line;
            width:100%;
            *{
              margin-bottom:0;
            }
            img{
              max-width:100%;
              height:auto;
              display: block;
              margin:0 auto;
            }
          }
        }
        >div{
          margin-bottom: .30rem;
        }
      }
    }
  }
}

