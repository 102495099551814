.education{
    min-width: 1200px;
    background: #F6F7F8;
    margin: 0 auto;
    max-width: 100%;
    .box {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        >img{
            margin: -10px;
        }
        .top_warp {
            width: 100%;
            background: #FFF;
            padding-top: 48px;
            margin-bottom: 20px;
            box-shadow: 0 5px 10px rgba($color: #000000, $alpha: .1);

            .top {
                width: 1200px;
                margin: 0 auto;
                border-bottom: 1px solid #E0E0E0;
                padding-bottom: 80px;
                display: flex;
                justify-content: flex-start;
                position: relative;

            }

            .btns {
                position: absolute;
                left: 0;
                bottom: 20px;
                width: 1200px;
                margin: 0 auto;
                //>span{
                //    display: inline-block;
                //    margin-right: 20px;
                //}
                //>button{
                //    width: 92px;
                //    height: 40px;
                //    margin-right: 20px;
                //    background: #F4F4F4;
                //    border-radius: 3px;
                //    &:nth-of-type(1){
                //        background: #1382E9;
                //        color: #FFF;
                //        border-radius: 3px;
                //    }
                //}
                >span{
                    display: inline-block;
                    margin-right: 20px;
                }
                >button{
                    width: auto;
                    margin-right: 20px;
                    height: 40px;
                    border-radius: 3px;
                }
                >.active{
                    background: #1382E9;
                    color: #FFF;
                }

            }

            .search {
                width: 1200px;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                height: 55px;
                margin-top: 15px;

                >span:nth-of-type(1) {
                    line-height: 40px;
                    font-size: 14px;
                    color: #888;
                    padding-top: 8px;
                }
            }
        }
    }
    .conten_box{
        width: 1200px;
        margin: 0 auto;
        .content{
            width: 100%;
            min-height: 173px;
            background: #FFFFFF;
            border-radius: 10px;
            padding: 30px 0 0 30px;
            margin-bottom: 20px;
            .content-c{
                float: left;
                display: flex;
                .date{
                    width: 82px;
                    height: 82px;
                    background: rgba(62, 120, 237, 0.1);
                    float: left;
                    display: inline-block;
                    span{
                        font-size: 36px;
                        color: #4286F5;
                        margin-left: 19px;
                        border-bottom: 1px solid #4286F5;
                    }
                    h2{
                        font-size: 14px;
                        color: #4286F5;
                        margin-left: 15px;
                    }
                }
                .right_wenzi{
                    float: left;
                    padding: 0 30px;
                    display: inline-block;
                    flex: 1 1;
                    cursor: pointer;
                    span{
                        font-size: 22px;
                        color: #333333;
                    }
                    h3{
                        font-size: 14px;
                        color: #999999;
                        margin-top: 10px;
                    }
                    h2{
                        color: #999999;
                        font-size: 12px;
                    }


                }
            }





        }
    }
}
