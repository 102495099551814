/*网站默哀灰色
html {
  filter: grayscale(100%);
}
*/
/*空数据*/
.Empty-conter{
  height: calc(100vh - 488px);
  display: flex;
  align-content: center;
  justify-content: center;
}
.Empty-conter .stu_live_empty{
  position: absolute;
  left:50%;
  top:50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.Empty-conet {
  width: 100%!important;
  display: flex!important;
  align-items: center!important;
  justify-content: center!important;
  min-height: 300px!important;
}
.Empty-conet .img {
  display: block!important;
  width: 200px!important;
}
.Empty-conet .img img{
  width:200px!important;
  height:auto!important;
  display: block!important;
  margin:0 auto!important;
}
.Empty-conet .img span{
  font-family:'Microsoft YaHei';
  font-style: normal;
  font-weight: normal;
  font-size: 16px!important;
  line-height: 120.7%!important;
  color: #888888!important;
  display: block!important;
  margin-top:20px!important;
  text-align: center!important;
}
/*加载中*/
.Spin-content {
  text-align: center;
  font-size: 12px;
  color: #888888;
  z-index: 4;
  display: block;
  width: 100%!important;
  height: 100%!important;
  max-height: 500px!important;
  margin-bottom:100px!important;
}
.Spin-content .ant-spin-dot{
  margin-top:5%!important;
}
.Spin-content .ant-spin-text{
  font-size: 12px;
  color: #888888;
}
