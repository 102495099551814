.modalEnterprises{
  .ant-modal-confirm-btns{
    .ant-btn-primary{
      background-color: #246EE9;
      border-color:#246EE9;
    }
  }
  svg{
    display: inline-block;
    width:16px;
    height:16px;
    position: relative;
    top:-3px;
    margin-right:10px;
  }
  .ant-modal-confirm-title{
    display: inline-block!important;
  }
  .ant-modal-confirm-content{
    padding-left:26px;
  }
}